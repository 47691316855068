import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faArrowDown,
	faBath,
	faCalendarDays,
	faConciergeBell,
	faTv,
	faCocktail,
	faCroissant,
	faShower,
	faWifi,
	faAirConditioner,
	faBed,
	faHotTubPerson,
	faCoffee,
	faLink,
	faVault,
	faWater,
	faCouch,
	faBars,
	faBenchTree,
	faMobile,
	faRulerCombined,
	faStars,
	faTimes,
	faTree,
	faUsers,
	faBlanket,
	faGlobe,
} from '@fortawesome/pro-light-svg-icons';

import {
	faChevronDown,
	faChevronUp,
	faCircleExclamation,
	faCheckCircle,
	faConciergeBell as faConciergeBellRegular,
	faCreditCard,
	faEnvelope,
	faGift,
	faHotel,
	faPhone,
	faWineGlass,
} from '@fortawesome/pro-regular-svg-icons';

import {
	faFacebook,
	faInstagram,
	faLinkedin,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faTiktok,
	faTwitter,
	faWhatsapp,
	faXTwitter,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import {
	faPlay,
	faSearch,
	faStar,
	faStarHalf,
	faMinus,
	faPlus,
	faCheck,
	faChevronDown as faChevronDownSolid,
	faXmark,
} from '@fortawesome/pro-solid-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faCoffee,
	faLink,
	faVault,
	faWater,
	faArrowDown,
	faBars,
	faBath,
	faBlanket,
	faBenchTree,
	faCalendarDays,
	faCheck,
	faChevronDown,
	faChevronDownSolid,
	faChevronUp,
	faCheckCircle,
	faConciergeBell,
	faConciergeBellRegular,
	faCocktail,
	faCroissant,
	faTree,
	faTv,
	faWifi,
	faAirConditioner,
	faBed,
	faHotTubPerson,
	faCouch,
	faCreditCard,
	faEnvelope,
	faGift,
	faGlobe,
	faHotel,
	faCircleExclamation,
	faTimes,
	faMinus,
	faMobile,
	faPlay,
	faSearch,
	faPlus,
	faFacebook,
	faInstagram,
	faLinkedin,
	faPhone,
	faPinterest,
	faShower,
	faSoundcloud,
	faSpotify,
	faStars,
	faTiktok,
	faTwitter,
	faWhatsapp,
	faYoutube,
	faStar,
	faStarHalf,
	faRulerCombined,
	faUsers,
	faWineGlass,
	faXmark,
	faXTwitter,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
